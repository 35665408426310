export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';
export const SET_ROLE_DATA = 'SET_ROLE_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_CONFIGURATION_DATA = 'SET_CONFIGURATION_DATA';
export const SET_NEW_PROJECT_DATA = 'SET_NEW_PROJECT_DATA';
export const SET_WORKFLOW_DATA = 'SET_WORKFLOW_DATA';
export const SET_NOTIFICATIONS_DATA = 'SET_NOTIFICATIONS_DATA';
export const SET_CLAIM_PROJECT_DATA = 'SET_CLAIM_PROJECT_DATA';
export const SET_REPORTS_DATA = 'SET_REPORTS_DATA';
export const SET_MYSHEETS_DATA = 'SET_MYSHEETS_DATA';
export const SET_DATABASE_DATA = 'SET_DATABASE_DATA';
